.home{
    position: relative;
    padding-bottom: 10vh;
}

.listItem{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
    font-size: 1.2em;
    border-bottom: 1px solid var(--mainTextColor);
}

#item0 .listItem{
    border-top: 1px solid var(--mainTextColor);
}


.listItem .itemTitle{
    grid-column: 1 / span 5;
}

.listItem .itemDate{
    grid-column: 6 / span 2;
}

.listItem .itemCategory{
    grid-column: 8 / span 2;
}

.listItem .itemLink{
    margin: 1em 0;
    grid-column: 11;
    width: fit-content;
    height: fit-content;
}

.itemsDivider{
    margin: -15px 0 0 0;
    padding: 0;
}

@media (max-width: 1395px){
    .listItem .itemDate{
        grid-column: 6 / span 2;
    }

    .listItem .itemCategory{
        grid-column: 8 / span 2;
    }

    .listItem .itemLink{
        grid-column: 10 / span 2;
    }
}

@media (max-width: 820px){
    .listItem {
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }

    .listItem .itemTitle{
        margin: 0;
        grid-column: 1 / span 3;
        grid-row: 1;
        font-size: 0.85em;
    }

    .listItem .itemDate{
        margin: 0;
        grid-column: 1;
        grid-row: 3;
        font-size: 0.85em;
    }

    .listItem .itemCategory{
        display: none;
        margin: 0;
        grid-column: 1 / span 3;
        grid-row: 2;
    }

    .listItem .itemLink{
        margin: 0 0 20px 0;
        margin-left: auto;
        grid-column: 3;
        grid-row: 3;
        font-size: 0.85em;
    }
}