.itemPageHeader{
    display:grid;
    grid-template-columns: repeat(12, 1fr);
    gap: var(--margins);
    animation: dissolveIn 1.5s ease-in;
}

.itemPageTitle{
    grid-column: 1 / span 9;
    animation: dissolveIn 2s ease-in;
}

.itemPageDate{
    grid-column: 10 / span 3;
    text-align: right;
    vertical-align: bottom;
    margin-top: auto;
    animation: dissolveIn 2s ease-in;
}

.itemPageBody{
    display:grid;
    grid-template-areas: "a a";
    grid-auto-columns: 1fr;
    gap: var(--margins);
    margin-bottom: 30px;
    animation: dissolveIn 2s ease-in;
}

.itemPageImageContainer{
    display:grid;
    grid-template-areas: "a a a";
    grid-auto-columns: 1fr;
    gap: var(--margins);
    animation: dissolveIn 2s ease-in;
}

.itemPageImageContainer .Image {
    width: 100%;
    height: auto;
}

.itemPageCite{
    margin-top: 50px;
    padding-bottom: 9vh;
    animation: dissolveIn 2s ease-in;
}

@media (max-width: 820px){
    .itemPageBody{
        display:grid;
        grid-template-areas: "a";
        margin-bottom: 30px;
        gap: 0px;
    }

    .itemPageImageContainer{
        display:grid;
        grid-template-areas: "a";
        padding-bottom: 9vh;
    }

    .itemPageCite{
        margin-top: 0px;
    }
    
    .itemPageContent{
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .itemPageContent p{
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 0;
        margin-bottom: 0;
    }
}