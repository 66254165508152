.footer{
    font-size: 1.1em;
    position: fixed;
    bottom: 0;
    width: calc(100% - calc(2 * var(--margins)));
    background-color: var(--backgroundColor);
    z-index: 10;
}

@media (max-width: 820px){
    .footer{
        font-size: .6em;
    }
}